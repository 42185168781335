<template>
    <div>
        
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="حسنا"
        back-button-text="السابق"
        next-button-text="التالي"
        class="mb-3"
        
      >
        <!-- accoint detailstab :before-change="validationForm" -->
        <tab-content title="معلومات الزيارة " >
          <validation-observer ref="accountRules" tag="form">
            <b-row>
                
              <b-col md="6" xl="4">
                <b-form-group label-for="phone">
                  <label>تاريخ الزيارة</label>
                  <flat-pickr class="form-control" disabled v-model="visit.visitModel.visit_date" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="كود الزيارة" label-for="number">
                  <validation-provider
                    #default="{ errors }"
                    name="number"
                    rules="required"
                  >
                    <b-form-input
                      id="c-number"
                      v-model="visit.visitModel.number"
                      type="string"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3" xl="4">
                <validation-provider
                  #default="{ errors }"
                  name="family"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label-for="familyNatural"
                  >
                    <label> طبيعة العائلة</label>
  
                    <v-select
                      v-model="visit.visitModel.family_status_id"
                      :options="FamilyStatus"
                      disabled
                      label="name"
                      :reduce="(val) => val.id"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="3" xl="4">
                <validation-provider
                  #default="{ errors }"
                  name="FormType"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    label-for="FormType"
                  >
                    <label> نوع الاستمارة</label>
  
                    <v-select
                      v-model="visit.visitModel.form_type"
                      :options="FormType"
                      disabled
                      :reduce="(val) => val.id"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <b-form-group label="اسم الاب" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="father_name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="visit.visitModel.father_name"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="اسم الام" label-for="mother_name">
                  <validation-provider
                    #default="{ errors }"
                    name="mother_name"
                    rules="required"
                  >
                    <b-form-input
                      id="mother_name"
                      v-model="visit.visitModel.mother_name"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="عدد الاطفال" label-for="childrens_count">
                  <validation-provider
                    #default="{ errors }"
                    name="childrens_count"
                    vid="childrens_count"
                    rules="required"
                  >
                    <b-form-input
                      id="childrens_count"
                      disabled
                      v-model="visit.visitModel.childrens_count"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="عدد المسنين " label-for="elders_count">
                  <validation-provider
                    #default="{ errors }"
                    name="elders_count"
                    rules="required"
                  >
                    <b-form-input
                      id="c-elders_count"
                      disabled
                      v-model="visit.visitModel.elders_count"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
              
                <b-form-group label="رقم الاتصال " label-for="contact">
                  <validation-provider
                    #default="{ errors }"
                    name="contact"
                    rules="required"
                  >
                  <b-form-tags
                      id="c-contact"
                      disabled
                      v-model="visit.visitModel.contact"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="مكان الاقامة الاصلي "
                  label-for="native_place"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="native_place"
                    rules="required"
                  >
                    <b-form-input
                      id="c-native_place"
                      v-model="visit.visitModel.native_place"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="نوع الاقامة " label-for="residence_type_id">
                  <validation-provider
                    #default="{ errors }"
                    name="residence_type_id"
                    rules="required"
                  >
                   
                    <v-select
                      v-model="visit.visitModel.residence_type_id"
                      :reduce="(val) => val.id"
                      disabled
                      :options="ResidenceTypes"
                      label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="تفاصيل الاقامة "
                  label-for="residence_description"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="residence_description"
                    rules="required"
                  >
                    <b-form-input
                      id="c-residence_description"
                      disabled
                      v-model="visit.visitModel.residence_description"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="idea">
                  <validation-provider
                    #default="{ errors }"
                    name="idea"
                    rules="required"
                  >
                    <label>
                      هل بوجد لدى احد افراد الاسرة فكرة واعدة لاعداد مشروع يساعد
                      من دخل الاسرة</label
                    >
                    <v-select
                      v-model="visit.visitModel.idea"
                      disabled
                      :reduce="(val) => val.id"
                      :options="ideaOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="visit.visitModel.idea == 1">
                <b-form-group label="نوع الفكرة " label-for="idea_type">
                  <validation-provider
                    #default="{ errors }"
                    name="idea_type"
                    rules="required"
                  >
                    <v-select
                      v-model="visit.visitModel.idea_type"
                      disabled
                      :reduce="(val) => val.id"
                      :options="ideaTypes"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" v-if="visit.visitModel.idea == 1">
                <b-form-group label="تفاصيل الفكرة " label-for="idea_description">
                  <validation-provider
                    #default="{ errors }"
                    name="idea_description"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="visit.visitModel.idea_description"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" xl="4">
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="visit.visitModel.volunteer_notes"
                    disabled
                    placeholder="الملاحظات"
                    rows="2"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
  
        <!-- personal details tab :before-change="validationFormInfo"     -->
        <tab-content title="نقاط الضعف  " >
          <validation-observer ref="infoRules" tag="form">
            <b-row v-for="(item, i) in visit.weaknessModel">
              <b-col md="4">
                <b-form-group     label=" الاسم الثلاثي" label-for="-name">
                  <validation-provider
                    #default="{ errors }"
                    name=" الاسم الثلاثي"
                    rules="required"
                  >
                    <b-form-input
                      id="-name"
                      v-model="item.name"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4">
                <b-form-group label-for="phone">
                  <label>تاريخ الميلاد</label>
                  <flat-pickr class="form-control" v-model="item.birth_date" disabled/>
                </b-form-group>
              </b-col>
              <b-col md="6" xl="2">
                <validation-provider
                  #default="{ errors }"
                  name="select"
                  rules="required"
                >
                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الجنس</label>
                    <v-select
                      v-model="item.gender"
                      disabled
                      :reduce="(val) => val.id"
                      :options="optiongender"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="2">
                <validation-provider
                  #default="{ errors }"
                  name="weakness"
                  :rules="{ subOptionRequired: { optionsWeak: optionsWeak } }"
                >
                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> نقطة الضعف</label>
                    <v-select
                      v-model="item.weakness"
                      disabled
                      :reduce="(val) => val.id"
                      :options="optionsWeak"
                      label="name"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="item.notes"
                    placeholder="الملاحظات"
                    disabled
                    rows="2"
                  />
                </b-form-group>
              </b-col>
        
            </b-row>
          
          </validation-observer>
        </tab-content>
  
        <!-- address :before-change="validationFormAddress" -->
        <tab-content title="التحويلات"   >
          <validation-observer ref="addressRules" tag="form">
            <b-row v-for="(item, i) in visit.referralModel">
              <b-col md="4">
                <b-form-group      label=" الاسم الثلاثي" label-for="-name">
                  <validation-provider
                    #default="{ errors }"
                    name=" الاسم الثلاثي"
                    rules="required"
                  >
                    <b-form-input
                      id="-name"
                      v-model="item.name"
                      disabled
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" xl="4">
                <b-form-group label-for="phone">
                  <label>تاريخ الميلاد</label>
                  <flat-pickr class="form-control" disabled v-model="item.birth_date" />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="2">
                <validation-provider
                  #default="{ errors }"
                  name="select"
                  rules="required"
                >
                  <b-form-group
                    label-for="gender"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الجنس</label>
                    <v-select
                      v-model="item.gender"
                      disabled
                      :reduce="(val) => val.id"
                      :options="optiongender"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4">
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="item.notes"
                    placeholder="الملاحظات"
                    disabled
                    rows="2"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider #default="{ errors }" name="service" rules="required">
                  <b-form-group
                    label-for="service"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الخدمة</label>
                    <v-select
                      v-model="item.main_service_id"
                      disabled
                      :reduce="(val) => val.value"
                      :options="optionService"
                      @input="getSubService(item.main_service_id)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="3">
                <validation-provider
                  #default="{ errors }"
                  name="documentType"
                  rules="required"
                >
                  <b-form-group
                    label-for="cardNumber"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الخدمة الفرعية </label>
                  <p> {{ item.sub_service_id.name }}  </p>
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
  
          
            </b-row>
        
          </validation-observer>
        </tab-content>
  
        <!-- social link -->
        <tab-content
          title="الاسئلة"
       
        >
          <validation-observer ref="socialRules" tag="form">
            <b-row v-for="(item, i) in visit.evaluationModel">
              <b-col md="4">
                <b-form-group label="" label-for="-name">
                
                  <label>  {{ evaluationModelQ.find((x) => x.key == item.key).label}}</label>
                </b-form-group>
              </b-col>
             
              <b-col md="6" xl="2">
                <validation-provider
                  #default="{ errors }"
                  name="select"
                  rules="required"
                >
                  <b-form-group
                    label-for="optionstatus"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الاجابة</label>
                    <v-select
                      v-model="item.status"
                      :reduce="(val) => val.id"
                      :options="optionstatus"
                      disabled
                    />
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col md="6" xl="4" v-if="item.status==1">
                <b-form-group label-for="notes">
                  <label>الملاحظات</label>
                  <b-form-textarea
                    id="textarea-volunteer_notes"
                    v-model="item.discription"
                    placeholder="الملاحظات"
                    rows="2"
                    disabled
                  />
                </b-form-group>
              </b-col>
              
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </div>
  </template>
  
  <script>
  import { FormWizard, TabContent } from "vue-form-wizard";
  import vSelect from "vue-select";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import { required, email, max, is_earlier } from "@validations";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTags,
    BFormTextarea,
    BButton,
  } from "bootstrap-vue";
  
  export default {
    components: {
      required,
      BButton,
      flatPickr,
      BFormTags,
      BFormTextarea,
      email,
      ValidationProvider,
      ValidationObserver,
      FormWizard,
      TabContent,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      vSelect,
      BFormInvalidFeedback,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
    },
    data() {
      return {
        FamilyStatus: [],
        optionsWeak: [],
        ResidenceTypes: [],
        FormType: [
          {
            id: 0,
            label: "فرد",
          },
          {
            id: 1,
            label: "عائلة",
          },
        ],
        optionstatus: [
          {
            id: 0,
            label: "لا",
          },
          {
            id: 1,
            label: "نعم",
          },
        ],
        optiongender: [
          {
            id: 2,
            label: "انثى",
          },
          {
            id: 1,
            label: "ذكر",
          },
        ],
        ideaTypes: [
          {
            id: 0,
            label: "تجارية",
          },
          {
            id: 1,
            label: "صناعية",
          },
          {
            id: 2,
            label: "خدمة",
          },
          {
            id: 3,
            label: "زراعية",
          },
        ],
        ideaOption: [
          {
            id: 0,
            label: "لا",
          },
          {
            id: 1,
            label: "نعم",
          },
        ],
        visit: {
          visitModel:{
          visit_date: "",
          family_status_id: "",
          father_name: "",
          mother_name: "",
          childrens_count: "",
          elders_count: "",
          number: "",
          form_type: "",
          contact: [],
          native_place: "",
          residence_type_id: "",
          residence_description: "",
          idea_description: "",
          idea: "",
          idea_type: "",
          volunteer_notes: "",
          },
          weaknessModel: [
            {
              name: "",
              gender: null,
  
              birtht_date: "",
              weakness: "",
              notes: "",
            },
          ],
          referralModel: [
            {
              name: "",
              gender: null,
              main_service_id:'',
          sub_service_id:'',
              birtht_date: "",
  
              notes: "",
            },
          ],
          evaluationModel: [
            {
              key: "woman_at_risk",
              label:'هل يوجد حالات تعنيف للمرأة ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "child_at_risk",
              label:'هل يوجد حالات تعنيف للاطقال ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "socity_at_fear",
              label:'هل يوجد حالات خوف من المجتمع  ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "family_brakUpCont",
              label:'هل يوجد حالات تفكك اسري  ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "risks",
              label:'هل يوجد حالات خطر واضحة ؟',
              status:1,
            
  
              discription: "",
            },
          ],
        },
        evaluationModelQ: [
            {
              key: "woman_at_risk",
              label:'هل يوجد حالات تعنيف للمرأة ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "child_at_risk",
              label:'هل يوجد حالات تعنيف للاطقال ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "socity_at_fear",
              label:'هل يوجد حالات خوف من المجتمع  ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "family_brakUpCont",
              label:'هل يوجد حالات تفكك اسري  ؟',
              status:1,
            
  
              discription: "",
            },
            {
              key: "risks",
              label:'هل يوجد حالات خطر واضحة ؟',
              status:1,
            
  
              discription: "",
            },
          ],
  
        countryName: [
          { value: "select_value", text: "Select Value" },
          { value: "Russia", text: "Russia" },
          { value: "Canada", text: "Canada" },
          { value: "China", text: "China" },
          { value: "United States", text: "United States" },
          { value: "Brazil", text: "Brazil" },
          { value: "Australia", text: "Australia" },
          { value: "India", text: "India" },
        ],
        languageName: [
          { value: "nothing_selected", text: "Nothing Selected" },
          { value: "English", text: "English" },
          { value: "Chinese", text: "Mandarin Chinese" },
          { value: "Hindi", text: "Hindi" },
          { value: "Spanish", text: "Spanish" },
          { value: "Arabic", text: "Arabic" },
          { value: "Malay", text: "Malay" },
          { value: "Russian", text: "Russian" },
        ],
        optionService: [],
        optionSubServices: [],
      };
     
    },
    created() {
      this.getFamilyStatus();
      this.getServiceType();
      this.getVisit()
    },
    methods: {
        getVisit() {
      this.$http.get(`/api/v1/orv/family-visit/${this.$route.params.id}`).then((res) => {
        //console.log('rewards', res.data.data)
        this.visit=res.data.data
       
    
      });
    },
      removeweaknessModel(index) {
        this.visit.weaknessModel.splice(index, 1);
      },
      getSubService(id){
        this.optionSubServices = [];
   
   let url = `/api/v1/main_services/${id}/proposal_services`;
   this.$http.get(url).then((res) => {
  
     let subServices = res.data.data;
     subServices.forEach((el) => {
       this.optionSubServices.push({ label: el.name, value: el.id });
     });
   });
  }
      ,
      getServiceType() {
        this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
  
          let proposal_services = res.data.data.main_services;
          proposal_services.forEach((el) => {
            this.optionService.push({ label: el.name, value: el.id });
          });
        });
      },
      addweaknessModel() {
        this.visit.weaknessModel.push({
          name: "",
          gender: null,
         
          birtht_date: "",
          weakness: "",
          notes: "",
        });
      },
      removereferralModel(index) {
        this.visit.referralModel.splice(index, 1);
      },
      addreferralModel() {
        this.visit.referralModel.push({
          name: "",
          gender: null,
          main_service_id:'',
          sub_service_id:'',
          birtht_date: "",
          weakness: "",
          notes: "",
        });
      },
      formSubmitted() {
        //console.log("bb",this.visit)
        this.$http.post("/api/v1/orv/store-visit-transaction", this.visit).then((res) => {
        //console.log("bb",res)
        this.$swal({
                    title: "",
  
                    text: `تم اضافة  
                 
           `,
                    icon: "success",
                    confirmButtonText: "موافق",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
        })
        
      },
      getFamilyStatus() {
        this.$http.get("/api/v1/family_statuses").then((response) => {
          this.FamilyStatus = response.data.data;
        });
        this.$http.get("/api/v1/residence_types").then((response) => {
          this.ResidenceTypes = response.data.data;
        });
        this.$http.get("/api/v1/weaknesses").then((response) => {
          //console.log("orv/family-visit-weakness", response);
          this.optionsWeak = response.data.data.weaknesses;
        });
      },
      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      validationFormInfo() {
        return new Promise((resolve, reject) => {
          this.$refs.infoRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      validationFormAddress() {
        return new Promise((resolve, reject) => {
          this.$refs.addressRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
      validationFormSocial() {
        return new Promise((resolve, reject) => {
          this.$refs.socialRules.validate().then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          });
        });
      },
    },
  };
  </script>
  <style lang="scss">
  label {
      color: #5e5873;
      font-size: 0.90rem !important;
  }
  @import "@core/scss/vue/libs/vue-wizard.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  